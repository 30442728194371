import React, { useState, useEffect } from "react";

import axios from "axios";
import Config from "../../config.json";

import Cookies from 'js-cookie';
import IzlagacGost from "./IzlagacGost";

const IzlagacGosti = props => {
  const [popis, setPopis] = useState([]);
  const [gost, setGost] = useState({});
  const [detalji, setDetalji] = useState({});

  useEffect(() => {
    ucitajGoste();
    ucitajDetaljeIzlagaca();
  }, []);

  const ucitajDetaljeIzlagaca = () => {
    setGost({
      tvrtka: props.izlagacDetalji.naziv,
      adresa: props.izlagacDetalji.adresa,
      postanski_broj: props.izlagacDetalji.postanski_broj,
      grad_mjesto: props.izlagacDetalji.grad_mjesto,
      drzava: props.izlagacDetalji.drzava
    });
  }

  const ucitajGoste = async () => {
    // puštam ovo da znam koji je stari api endpoint za ucitavanje gostiju.
    //let url = Config.apiEndpoint + "/event/izlagac/ucitaj-goste";
    const response = await axios.get("https://api.horeca-adria.com:8081/izlagac/gost/" + props.izlagac + "/" + props.event, {
      headers: {
        authorization: Cookies.get("token")
      }
    });
    setPopis(response.data.result);
  };

  const onUpisGosta = () => {
    axios.post("https://api.horeca-adria.com:8081/izlagac/gost/", {
      event: props.event,
      gost: gost,
      izlagac: props.izlagac
    }, 
    {
      headers: {
        authorization: Cookies.get("token")
      }
    }).then(response => {
      ucitajGoste();
      setGost({});
    
    });



  };

  return (
    <div
      className="mt-5"
      style={{ backgroundColor: "#ede9e8", padding: "20px" }}
    >
      <div style={{ fontSize: "16px", fontWeight: "700" }}>Gosti izlagača</div>
      <div className="text-right mb-4">
        <a className="btn btn-secondary" data-bs-toggle="offcanvas" href="#offcanvasDodajGosta" role="button" aria-controls="offcanvasDodajGosta">Upiši novog</a>
      </div>

      <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasDodajGosta" aria-labelledby="offcanvasDodajGostaLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasDodajGostaLabel">Upis novog gosta</h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
                <select
                  className="form-select mb-3" aria-label="Oslovljavanje"
                  name="oslovljavanje"
                  value={gost.oslovljavanje}
                  onChange={event => setGost({ ...gost, oslovljavanje: event.target.value })}
                >
                  <option value="">Oslovljavanje</option>
                  <option value="g.">g.</option>
                  <option value="gđa">gđa</option>
                  <option value="gđica">gđica</option>
                </select>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="ime"
                  className="form-control"
                  value={gost.ime}
                  onChange={event => setGost({ ...gost, ime: event.target.value })}
                />
                <label htmlFor="ime" className="for-label">Ime</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="prezime"
                  className="form-control"
                  value={gost.prezime}
                  onChange={event => setGost({ ...gost, prezime: event.target.value })}
                />
                <label htmlFor="prezime" className="for-label">Prezime</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="tvrtka"
                  className="form-control"
                  value={gost.tvrtka}
                  onChange={event => setGost({ ...gost, tvrtka: event.target.value })}
                />
                <label htmlFor="tvrtka" className="for-label">Tvrtka</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="funkcija"
                  className="form-control"
                  value={gost.funkcija}
                  onChange={event => setGost({ ...gost, funkcija: event.target.value })}
                />
                <label htmlFor="funkcija" className="for-label">Funkcija</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  value={gost.email}
                  onChange={event => setGost({ ...gost, email: event.target.value })}
                />
                <label htmlFor="email" className="for-label">E-mail</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="adresa"
                  className="form-control"
                  value={gost.adresa}
                  onChange={event => setGost({ ...gost, adresa: event.target.value })}
                />
                <label htmlFor="adresa" className="for-label">Adresa</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="postanski_broj"
                  className="form-control"
                  value={gost.postanski_broj}
                  onChange={event => setGost({ ...gost, postanski_broj: event.target.value })}
                />
                <label htmlFor="postanski_broj" className="for-label">Poštanski broj</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="grad_mjesto"
                  className="form-control"
                  value={gost.grad_mjesto}
                  onChange={event => setGost({ ...gost, grad_mjesto: event.target.value })}
                />
                <label htmlFor="grad_mjesto" className="for-label">Grad</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="drzava"
                  className="form-control"
                  value={gost.drzava}
                  onChange={event => setGost({ ...gost, drzava: event.target.value })}
                />
                <label htmlFor="drzava" className="for-label">Država</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="mobitel"
                  className="form-control"
                  value={gost.mobitel}
                  onChange={event => setGost({ ...gost, mobitel: event.target.value })}
                />
                <label htmlFor="mobitel" className="for-label">Mobitel</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="djelatnost"
                  className="form-control"
                  value={gost.djelatnost || "Ostalo"}
                  onChange={event => setGost({ ...gost, djelatnost: event.target.value })}
                />
                <label htmlFor="djelatnost" className="for-label">Djelatnost</label>
              </div>
              <div className="col-sm-12 text-center">
                <button className="btn btn-success" onClick={onUpisGosta}>Upiši gosta</button>
              </div>
        </div>
      </div>

      <div className="container">
        <div className="row mb-3">
          <div className="col-2 fw-bold">Ime</div>
          <div className="col-2 fw-bold">Prezime</div>
          <div className="col-2 fw-bold">Tvrtka</div>
          <div className="col-2 fw-bold">Grad</div>
          <div className="col-2 fw-bold">E-mail</div>
        </div>
        {popis.map(gost => <IzlagacGost gost={gost.id} key={gost.id} />)}
      </div>
    </div>
  );
};

export default IzlagacGosti;
