import React, { useEffect, useState } from "react";

import axios from 'axios';
import Cookies from 'js-cookie';

const Statistika = (props) => {
    const [poDjelatnosti, setPoDjelatnosti] = useState([]);

    useEffect(() => {
        ucitajPoDjelatnosti();
    },[]);

    const ucitajPoDjelatnosti = () => {
        axios.get("https://api.horeca-adria.com:8081/stats/prijava/108",
        {
            headers: {
                authorization: Cookies.get("token")
            }
        }).then(response => setPoDjelatnosti(response.data.result));
    }

  return (
    <div className="m-3">
      <h2>Statistika po djelatnosti</h2>
      <table className="table table-sm table-striped" style={{ width: '700px', margin: '0px auto' }}>
        <thead>
            <tr>
                <th scope="col">Djelatnost</th>
                <th scope="col">Zadnja 24h</th>
                <th scope="col">Zadnjih 7 dana</th>
                <th scope="col">Od početka</th>
            </tr>
        </thead>
        <tbody>
            {poDjelatnosti.map(stat => (
                <tr>
                    <th scope="row">{stat.djelatnost}</th>
                    <td className="text-center">{stat.zadnja_24h}</td>
                    <td className="text-center">{stat.zadnjih_7d}</td>
                    <td className="text-center">{stat.od_pocetka}</td>
                </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
};

export default Statistika;
