import React, { useEffect, useState } from "react";
import axios from 'axios';
import Cookies from "js-cookie";


const IzlagacOpis = (props) => {
    const [naziv, setNaziv] = useState("");
    const [adresa, setAdresa] = useState("");
    const [telefon, setTelefon] = useState("");
    const [web_adresa, setWebAdresa] = useState("");
    const [email, setEmail] = useState("");
    const [opis, setOpis] = useState("");

    const [details, setDetails] = useState({
        naziv: '',
        adresa: '',
        postanski_broj: '',
        grad_mjesto: '',
        drzava: '',
        telefon: '',
        oib: '',
        web_adresa: '',
        email: '',
        opis: ''
    });

    useEffect(() => {
        ucitajIzlagaca();
    }, [])

    const ucitajIzlagaca = () => {
        axios.get("https://api.horeca-adria.com:8081/izlagac/detalji/" + props.izlagac,
          {
            headers: {
              authorization: Cookies.get("token"),
            },
          }
        ).then(response => setDetails(response.data.result[0]));
    }

    const snimiPromjene = () => {
        axios.put("https://api.horeca-adria.com:8081/izlagac/" + props.izlagac,
            {
                izlagac: details
            },
            {
                headers: {
                    authorization: Cookies.get("token")
                }
            },
        );
    }

  return (
    <div>
        <div className="text-right mr-4">
            <a className="btn btn-primary" data-bs-toggle="offcanvas" href="#offcanvasAzurirajIzlagaca" role="button" aria-controls="offcanvasAzurirajIzlagaca">
                Ažuriraj izlagača
            </a>
        </div>
        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasAzurirajIzlagaca" aria-labelledby="offcanvasAzurirajIzlagacaLabel">
        <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasAzurirajIzlagacaLabel">Ažuriraj izlagača</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <div className="mt-3">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="naziv" value={details.naziv} onChange={event => setDetails({ ...details, naziv: event.target.value })} />
                    <label htmlFor="naziv">Naziv</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="adresa" value={details.adresa} onChange={event => setDetails({ ...details, adresa: event.target.value })} />
                    <label htmlFor="adresa">Adresa</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="postanski_broj" value={details.postanski_broj} onChange={event => setDetails({ ...details, postanski_broj: event.target.value })} />
                    <label htmlFor="postanski_broj">Poštanski broj</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="grad_mjesto" value={details.grad_mjesto} onChange={event => setDetails({ ...details, grad_mjesto: event.target.value })} />
                    <label htmlFor="grad_mjesto">Grad</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="drzava" value={details.drzava} onChange={event => setDetails({ ...details, drzava: event.target.value })} />
                    <label htmlFor="drzava">Država</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="telefon" value={details.telefon} onChange={event => setDetails({ ...details, telefon: event.target.value })} />
                    <label htmlFor="telefon">Telefon</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="oib" value={details.oib} onChange={event => setDetails({ ...details, oib: event.target.value })} />
                    <label htmlFor="oib">OIB</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="webAdresa" value={details.web_adresa} onChange={event => setDetails({ ...details, web_adresa: event.target.value })} />
                    <label htmlFor="webAdresa">Web</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="email" value={details.email} onChange={event => setDetails({ ...details, email: event.target.value })} />
                    <label htmlFor="email">E-mail</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="opis" value={details.opis} onChange={event => setDetails({ ...details, opis: event.target.value })} />
                    <label htmlFor="opis">Opis</label>
                </div>
            <button className="btn btn-primary" onClick={snimiPromjene}>Snimi</button>
            </div>
        </div>
        </div>
      <h1>{details.naziv}</h1>
        <h4>Adresa: {details.adresa}, {details.postanski_broj} {details.grad_mjesto}</h4>
        <h4>Država: {details.drzava}</h4>
        <h4>Telefon: {details.telefon}</h4>
        <h4>OIB: {details.oib}</h4>
        <h4>Web: {details.web_adresa}</h4>
        <h4>E-mail: {details.email}</h4>
        <div className="m-3">
          <h5>Opis izlagača:</h5>
          <h6>{details.opis}</h6>
        </div>
    </div>
  )
};

export default IzlagacOpis;
