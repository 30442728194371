import React from "react";

import { Link } from "react-router-dom";
import logo from "../images/xpocrm_logo.png";

const GlobalNavBar = () => {
  return (
    <header
    /*style={{
        position: "absolute",
        width: "100%",
        paddingLeft: "6.25rem",
        paddingRight: "6.25rem",
        top: "2.1875rem"
      }}*/
    >
      <nav
        id="globalNavBar"
        className="globalNavBar navbar navbar-expand-lg navbar-light bg-light bgcolor"
      >
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <a className="nav-link" href="/">
                <img src={logo} alt="Logo" style={{ width: "130px" }} />
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" href="/whye2blink">
                Why E2Blink
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/marketing">
                Marketing
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/pricing">
                Pricing
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/resources">
                Resources
              </a>
            </li> */}
          </ul>
          <ul>
            <li className="d-inline">
              {/* <Link to="/login">
                <button className="button-primary">Log In</button>
              </Link> */}
            </li>
            {/* <li className="d-inline">
              <Link to="/signup">
                <button className="button-secondary">Sign Up</button>
              </Link>
            </li> */}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default GlobalNavBar;
