import React, { useState, useEffect } from "react";

import axios from "axios";
import Config from "../../config.json";
import Cookies from 'js-cookie';

const Sastanci = () => {
  const [data, setData] = useState([]);

  const styles = {
    naslov: {
      fontSize: "25px",
      fontWeight: 700
    },
    input: {
      width: "300px",
      marginBottom: "30px"
    }
  };

  useEffect(() => {
    ucitajPodatke();
  }, []);

  const ucitajPodatke = async () => {
    let url = Config.apiEndpoint + "/event/sastanci/popis/";
    const response = await axios.get(url,
      {
        headers: {
          authorization: Cookies.get("token"),
          event: 107
        }
      });
    setData(response.data);
  };

  return (
    <div>
      <div style={styles.naslov}>Sastanci</div>

      <div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col" className="naslov-popis">
                E-mail predstavnika
              </th>
              <th scope="col" className="naslov-popis">
                Naziv izlagača
              </th>
              <th scope="col" className="naslov-popis">
                Vrijeme sastanka
              </th>
              <th scope="col" className="naslov-popis">
                Posjetitelj
              </th>
              <th scope="col" className="naslov-popis">
                Tvrtka
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((sastanak, index) => (
              <tr key={index}>
                <td className="tekst-event-sastanci">
                  {sastanak.email_predstavnika}
                </td>
                <td className="tekst-event-sastanci">
                  {sastanak.naziv_izlagaca}
                </td>
                <td className="tekst-event-sastanci">
                  {sastanak.vrijeme_sastanka}
                </td>
                <td className="tekst-event-sastanci">{sastanak.posjetitelj}</td>
                <td className="tekst-event-sastanci">
                  {sastanak.posjetitelj_tvrtka}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Sastanci;
