import React, { useEffect, useState } from "react";
import axios from 'axios';
import Cookies from 'js-cookie';

const IzlagacGost = (props) => {

  const [gost, setGost] = useState({
    oslovljavanje: '',
    ime: '',
    prezime: '',
    tvrtka: '',
    funkcija: '',
    email: '',
    adresa: '',
    postanski_broj: '',
    grad_mjesto: '',
    drzava: '',
    mobitel: '',
    djelatnost: ''
  });
  const [serverResponse, setServerResponse] = useState(false);

  useEffect(() => {
    ucitaj();
  },[]);

  const ucitaj = async () => {
    const response = await axios.get("https://api.horeca-adria.com:8081/izlagac/gost/" + props.gost,
    {
      headers: {
        authorization: Cookies.get("token")
      }
    });
    setGost(response.data.result[0]);
  }

  const snimi = () => {
    axios.put("https://api.horeca-adria.com:8081/izlagac/gost/edit/" + props.gost,
    {
      gost,
    },
    {
      headers: {
        authorization: Cookies.get("token")
      }
    }).then(response => response.data.result ? setServerResponse(true) : setServerResponse(false));
  }

  return (
    <>
    <div className="row">
      <div className="offcanvas offcanvas-end" tabIndex="-1" id={"offcanvasIzmjenaGosta" + props.gost} aria-labelledby={"offcanvasIzmjenaGostaLabel" + props.gost}>
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id={"offcanvasIzmjenaGostaLabel" + props.gost}>Gosti izlagača</h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
                <select
                  name="oslovljavanje"
                  className="form-select mb-3"
                  aria-label="Oslovljavanje"
                  value={gost.oslovljavanje || props.oslovljavanje}
                  onChange={event => setGost({ ...gost, oslovljavanje: event.target.value })}
                >
                  <option>Oslovljavanje</option>
                  <option value="g.">g.</option>
                  <option value="gđa">gđa</option>
                  <option value="gđica">gđica</option>
                </select>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="ime"
                  className="form-control"
                  value={gost.ime}
                  onChange={event => setGost({ ...gost, ime: event.target.value })}
                />
                <label htmlFor="ime" className="for-label">Ime</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="prezime"
                  className="form-control"
                  value={gost.prezime}
                  onChange={event => setGost({ ...gost, prezime: event.target.value })}
                />
                <label htmlFor="prezime" className="for-label">Prezime</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="tvrtka"
                  className="form-control"
                  value={gost.tvrtka}
                  onChange={event => setGost({ ...gost, tvrtka: event.target.value })}
                />
                <label htmlFor="tvrtka" className="for-label">Tvrtka</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="funkcija"
                  className="form-control"
                  value={gost.funkcija}
                  onChange={event => setGost({ ...gost, funkcija: event.target.value })}
                />
                <label htmlFor="funkcija" className="for-label">Funkcija</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  value={gost.email}
                  onChange={event => setGost({ ...gost, email: event.target.value })}
                />
                <label htmlFor="email" className="for-label">E-mail</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="adresa"
                  className="form-control"
                  value={gost.adresa}
                  onChange={event => setGost({ ...gost, adresa: event.target.value })}
                />
                <label htmlFor="email" className="for-label">Adresa</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="postanski_broj"
                  className="form-control"
                  value={gost.postanski_broj}
                  onChange={event => setGost({ ...gost, postanski_broj: event.target.value })}
                />
                <label htmlFor="adresa" className="for-label">Poštanski broj</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="grad_mjesto"
                  className="form-control"
                  value={gost.grad_mjesto}
                  onChange={event => setGost({ ...gost, grad_mjesto: event.target.value })}
                />
                <label htmlFor="postanski_broj" className="for-label">Grad</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="drzava"
                  className="form-control"
                  value={gost.drzava}
                  onChange={event => setGost({ ...gost, drzava: event.target.value })}
                />
                <label htmlFor="grad_mjesto" className="for-label">Država</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="mobitel"
                  className="form-control"
                  value={gost.mobitel}
                  onChange={event => setGost({ ...gost, mobitel: event.target.value })}
                />
                <label htmlFor="mobitel" className="for-label">Mobitel</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="djelatnost"
                  className="form-control"
                  value={gost.djelatnost || "Ostalo"}
                  onChange={event => setGost({ ...gost, djelatnost: event.target.value })}
                />
                <label htmlFor="djelatnost" className="col-sm-6 text-right">Djelatnost</label>
              </div>
              <div className="col-sm-12 text-center">
                <button className="btn btn-success" onClick={snimi}>Snimi</button>
              </div>
            {serverResponse && <div className="text-right">Podaci uspješno snimljeni</div>}
          </div>
      </div>
    </div>
  <div className="row mb-2">
        <div className="col-2">{gost.ime}</div>
        <div className="col-2">{gost.prezime}</div>
        <div className="col-2">{gost.tvrtka}</div>
        <div className="col-2">{gost.grad_mjesto}</div>
        <div className="col-2">{gost.email}</div>
        <div className="col-2">
          <div className="row">
            <div className="col mb-2">
              <a className="btn btn-sm btn-primary" data-bs-toggle="offcanvas" href={"#offcanvasIzmjenaGosta" + props.gost} role="button" aria-controls={"offcanvasIzmjenaGosta" + props.gost}>
                Detalji
              </a></div>
            <div className="col"><button className="btn btn-sm btn-secondary" title="Izbriši sa sajma">Izbriši</button></div>
          </div>
        </div>
    </div>
    </>
  )
};

export default IzlagacGost;
