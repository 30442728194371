import React, { useState, useEffect } from "react";

import axios from "axios";
import Config from "../../config.json";
import Cookies from "js-cookie";
//import { useParams } from "react-router-dom";

import readXlsxFile from 'read-excel-file';
import IzlagacPredstavnik from "./IzlagacPredstavnik";
import IzlagacPredstavniciPostojeci from "./IzlagacPredstavniciPostojeci";

const IzlagacPredstavnici = (props) => {
  const [popis, setPopis] = useState([]);
  const [predstavnik, setPredstavnik] = useState({});
  const [postojeciPredstavnik, setPostojeciPredstavnik] = useState({});
  const [predstavnikEventi, setPredstavnikEventi] = useState({});
  const [detalji, setDetalji] = useState({});
  const [predstavnikPostoji, setPredstavnikPostoji] = useState(false);
  const [popisUlaznica, setPopisUlaznica] = useState([]);
  const [spojenaUlaznica, setSpojenaUlaznica] = useState({});

  const [popisPostojecihPredstavnikaIzlagac, setPopisPostojecihPredstavnikaIzlagac] = useState([]);

  const [isPostojeciPredstavnikVisible, setIsPostojeciPredstavnikVisible] = useState(false);

  const [upisiNovogForma, setUpisiNovogForma] = useState(false);

  const [ime, setIme] = useState("");
  const [prezime, setPrezime] = useState("");
  const [funkcija, setFunkcija] = useState("");
  const [email, setEmail] = useState("");
  const [mobitel, setMobitel] = useState("");
  const [djelatnost, setDjelatnost] = useState("");

  const [kopiranjePredstavnika, setKopiranjePredstavnika] = useState(false);
  const [predstavnikZaKopiranje, setPredstavnikZaKopiranje] = useState({});

  //const { izlagac } = useParams();

  useEffect(() => {
    ucitajPredstavnike();
    ucitajPostojecePredstavnikeIzlagac();
    ucitajDetaljeIzlagaca();
  }, []);

  const ucitajPostojecePredstavnikeIzlagac = () => {
    axios.get("https://api.horeca-adria.com:8081/predstavnik/postojeci/" + props.izlagac + "/" + props.event,
    {
      headers: {
        authorization: Cookies.get("token")
      }
    }).then(response => {
      setPopisPostojecihPredstavnikaIzlagac(response.data.result)
    });
  }

  const ucitajPredstavnike = async () => {
    const response = await axios.get(
      "https://api.horeca-adria.com:8081/izlagac/predstavnik/" + props.izlagac + "/" + props.event,
      {
        headers: {
          authorization: Cookies.get("token"),
        },
      }
    );
    setPopis(response.data.result);
  };

  const snimi = async (event) => {
    event.preventDefault();

    if (kopiranjePredstavnika) {
      upisiPredstavnika(predstavnikZaKopiranje);
      ucitajPredstavnike();

      setIme("");
      setPrezime("");
      setFunkcija("");
      setEmail("");
      setMobitel("");
      setDjelatnost("");

      setUpisiNovogForma(false);
      setKopiranjePredstavnika(false);
      
    } else {

    let url = Config.apiEndpoint + "/event/izlagac/dodaj-predstavnika";
    const response = await axios.post(
      url,
      {
        ime: ime,
        prezime: prezime,
        funkcija: funkcija,
        email: email,
        mobitel: mobitel,
        djelatnost: djelatnost,
        izlagac: props.izlagac,

      },
      {
        headers: {
          authorization: Cookies.get("token"),
        },
      }
    );

    ucitajPredstavnike();

    const insertId = response.data;

    setIme("");
    setPrezime("");
    setFunkcija("");
    setEmail("");
    setMobitel("");
    setDjelatnost("");

    setUpisiNovogForma(false);
    }
  };

  const onProvjeriPredstavnika = () => {
    if (predstavnik.email == '') {
      predstavnik.email = ' ';
    }
    axios.get("https://api.horeca-adria.com:8081/predstavnik/provjera/" + predstavnik.email + "/" + props.event + "/" + props.izlagac, {
      headers: {
        authorization: Cookies.get("token")
      }
    }).then(response => {
      if (response.data.result) {
        setPredstavnik(response.data.result[0]);
        setPredstavnikEventi(response.data.result_eventi[0]);
        setDetalji(response.data.result_detalji[0]);
        setPredstavnikPostoji(true);
        onProvjeriPostojeceUlaznice();
      } else {
        setPredstavnik({ ...predstavnik, ime: '', prezime: '', password: '' });
        //setDetalji({ ...detalji, naziv: '', grad_mjesto: '', mobitel: '', adresa: '', postanski_broj: '', drzava: 'Hrvatska' });
        setPredstavnikPostoji(false);
        setPopisUlaznica([]);
      }
    });
  }

  

  const upisiPredstavnika = async (predstavnik) => {
    console.log("upisi predstavnika");
    const url = Config.apiEndpoint + "/event/izlagac/dodaj-postojeceg-predstavnika";
    const response = await axios.post(url, {
      idPredstavnika: predstavnik.id,
      izlagac: props.izlagac,
      ime: ime,
      prezime: prezime,
      email: email,
      funkcija: funkcija,
      mobitel: mobitel
    },
    {
      headers: {
        authorization: Cookies.get("token")
      }
    });
  }

  const upisiNovogPredstavnika = () => {
    if (!detalji.naziv) {
      alert('Niste ispunili naziv tvrtke.');
      return false;
    }
    if (!detalji.grad_mjesto) {
      alert('Niste ispunili naziv grada.');
      return false;
    }
    axios.post("https://api.horeca-adria.com:8081/izlagac/predstavnik/",
      {
        predstavnik,
        event: props.event,
        izlagac: props.izlagac,
        detalji
      },
      {
        headers: {
          authorization: Cookies.get("token")
        }
      }
    ).then(response => ucitajPredstavnike())
    .catch(error => console.log(error));
  }

  const onDodajNaEvent = () => {
    axios.post("https://api.horeca-adria.com:8081/izlagac/predstavnik/event/",
    {
      predstavnik: predstavnik.id,
      event: props.event,
      izlagac: props.izlagac
    },
    {
      headers: {
        authorization: Cookies.get("token")
      }
    }).then(response => {
      ucitajPredstavnike();
    });
  }

  const onDodajNaEventKreirajUlaznicu = predstavnik => {
    axios.post("https://api.horeca-adria.com:8081/ulaznica/predstavnik/",
    {
      ulaznica: {
        ime: predstavnik.ime,
        prezime: predstavnik.prezime,
        email: predstavnik.email,
        tvrtka: detalji.naziv,
        grad_mjesto: detalji.grad_mjesto,
        mobitel: detalji.mobitel, 
        adresa: detalji.adresa,
        postanski_broj: detalji.postanski_broj,
        drzava: detalji.drzava
      },
      event: props.event,
      predstavnik: predstavnik.id,
      izlagac: props.izlagac
    },
    {
      headers: {
        authorization: Cookies.get("token")
      }
    }).then(response => ucitajPredstavnike());
  }

  const ucitajDetaljeIzlagaca = () => {
    setDetalji({
      naziv: props.izlagacDetalji.naziv,
      grad_mjesto: props.izlagacDetalji.grad_mjesto,
      mobitel: props.izlagacDetalji.telefon,
      adresa: props.izlagacDetalji.adresa,
      postanski_broj: props.izlagacDetalji.postanski_broj,
      drzava: props.izlagacDetalji.drzava 
    });
  }

  const onProvjeriPostojeceUlaznice = () => {
    axios.get("https://api.horeca-adria.com:8081/ulaznica/" + props.event + "/" + predstavnik.email, 
    {
      headers: {
        authorization: Cookies.get("token")
      }
    }).then(response => {
      console.log("On provjeri postojeće ulaznice: ", response);
      setPopisUlaznica(response.data.postojeceUlaznice);
      setSpojenaUlaznica(response.data.spojenaUlaznica);
      console.log("Spojena ulaznica: ", spojenaUlaznica.length);
    });
  }

  const onSpojiUlaznicu = ulaznica => {
    setSpojenaUlaznica(ulaznica);
  }

  const onAzurirajPredstavnika = () => {
    axios.put("https://api.horeca-adria.com:8081/izlagac/predstavnik/",
    {
      ime: predstavnik.ime,
      prezime: predstavnik.prezime,
      id: predstavnik.id
    },
    {
      headers: {
        authorization: Cookies.get("token")
      }
    }).then(response => console.log(response)).catch(error => console.log(error));
  }

  return (
    <div
      className='mt-5'
      style={{ backgroundColor: "#ede9e8", padding: "20px" }}
    >
      <div style={{ fontSize: "18px", fontWeight: "700" }}>
        Predstavnici izlagača
      </div>
      <div className='text-right mb-4'>
        <a
          className="btn btn-secondary"
          data-bs-toggle="offcanvas" href={"#offcanvasDodajPredstavnika" + props.event} role="button" aria-controls={"offcanvasDodajPredstavnika" + props.event}
          // onClick={() => setUpisiNovogForma(!upisiNovogForma)}
        >
          Upiši novog
        </a>
      </div>
      
      <div className="offcanvas offcanvas-end" tabIndex="-1" id={"offcanvasDodajPredstavnika" + props.event} aria-labelledby={"offcanvasDodajPredstavnikaLabel" + props.event}>
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id={"offcanvasDodajPredstavnikaLabel" + props.event}>Upis novog predstavnika</h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <h3 className="mt-3 mb3">Podaci za aplikaciju xpoCRM</h3>
          <div className="text-right mb-3">
            <button className="btn btn-sm btn-primary" onClick={() => setPredstavnik({ email: '', ime: '', prezime: '', password: '' })}>Reset forme</button>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-6">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    name="email"
                    className="form-control"
                    value={predstavnik.email}
                    onChange={(event) => setPredstavnik({ ...predstavnik, email: event.target.value })}
                    onBlur={(event) => onProvjeriPredstavnika()}
                    />
                  <label htmlFor="email" className="for-label">E-mail</label>
                </div>
              </div>
              <div className="col-6">
                <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="password"
                      className="form-control"
                      value={predstavnik.password}
                      disabled
                      // onChange={(event) => setPredstavnik({ ...predstavnik, password: event.target.value })}
                      />
                    <label htmlFor="prezime" className="for-label">Password</label>
                </div>           
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-floating mb-3">
                  <input
                      type="text"
                      name="ime"
                      className="form-control"
                      value={predstavnik.ime}
                      onChange={(event) => setPredstavnik({ ...predstavnik, ime: event.target.value })}
                      />
                  <label htmlFor="ime" className="for-label">Ime</label>
                </div>
              </div>
              <div className="col-6">
                <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="prezime"
                      className="form-control"
                      value={predstavnik.prezime}
                      onChange={(event) => setPredstavnik({ ...predstavnik, prezime: event.target.value })}
                      />
                    <label htmlFor="prezime" className="for-label">Prezime</label>
                </div>           
              </div>
            </div>
            {/* <div className="row">
              <div className="col">
                <button className="btn btn-sm btn-success" onClick={onDodajNaEvent}>Dodaj predstavnika na sajam</button>
              </div>
            </div> */}
          </div>
                
                    
                    <div className="container">
                      <div className="row mt-4">
                        <div className="col">
                          <h4 className="mt-3 mb-3">Podaci za novu ulaznicu</h4>
                        </div>
                        {/* <div className="col-4">
                          <button className="btn btn-sm btn-success">Učitaj podatke sa izlagača</button>
                        </div> */}
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-floating mb-3">
                            <input type="text" name="tvrtka" className="form-control" value={detalji.naziv} onChange={event => setDetalji({ ...detalji, naziv: event.target.value})} />
                            <label htmlFor="tvrtka" className="for-label" value={detalji.naziv}>Tvrtka</label>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-floating mb-3">
                            <input type="text" name="grad_mjesto" className="form-control" value={detalji.grad_mjesto} onChange={event => setDetalji({ ...detalji, grad_mjesto: event.target.value })} />
                            <label htmlFor="grad_mjesto" className="for-label">Grad</label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-floating mb-3">
                            <input type="text" name="mobitel" className="form-control" value={detalji.mobitel} onChange={event => setDetalji({ ...detalji, mobitel: event.target.value })} />
                            <label htmlFor="mobitel" className="for-label">Mobitel</label>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-floating mb-3">
                            <input type="text" name="adresa" className="form-control" value={detalji.adresa} onChange={event => setDetalji({ ...detalji, adresa: event.target.value })} />
                            <label htmlFor="adresa" className="for-label">Adresa</label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-floating mb-3">
                            <input type="text" name="postanski_broj" className="form-control" value={detalji.postanski_broj} onChange={event => setDetalji({ ...detalji, postanski_broj: event.target.value })} />
                            <label htmlFor="postanski_broj" className="for-label">Poštanski broj</label>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-floating mb-3">
                            <input type="text" name="drzava" className="form-control" value={detalji.drzava} onChange={event => setDetalji({ ...detalji, drzava: event.target.value })} />
                            <label htmlFor="drzava" className="for-label">Država</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  {predstavnikPostoji ? <button className="btn btn-sm btn-success" onClick={onAzurirajPredstavnika}>Spremi promjene</button> : <button className="btn btn-success m-4" onClick={upisiNovogPredstavnika}>Upiši predstavnika i dodaj na sajam {props.event} </button>}
                <div>{(predstavnikEventi && predstavnikEventi.event) ? <div>Predstavnik već postoji na sajmu</div> : <button className="btn btn-sm btn-primary" onClick={onDodajNaEvent}>Dodaj predstavnika na sajam {props.event}</button>}</div>
                <div className="card mt-3">
                  <h3>Predstavnici izlagača koji nisu dodani na sajam</h3>
                  {popisPostojecihPredstavnikaIzlagac.map(predstavnik => <div className="m-2" key={predstavnik.id}>{predstavnik.ime} {predstavnik.prezime} ({predstavnik.email})<button className="btn btn-sm btn-primary" onClick={() => onDodajNaEvent(predstavnik)}>Dodaj na sajam</button><button className="btn btn-sm btn-secondary" onClick={() => onDodajNaEventKreirajUlaznicu(predstavnik)}>Dodaj na sajam i kreiraj ulaznicu</button></div>)}
                </div>
                {/* {popisUlaznica && popisUlaznica.length > 0 &&
                <div className="col-sm-12 text-center">
                    <h5>Popis postojećih ulaznica sa e-mailom {predstavnik.email}</h5>
                    {popisUlaznica.map(ulaznica => (
                      <div className="card" key={ulaznica.id}>
                        <div>{ulaznica.ime} {ulaznica.prezime}</div>
                        <div>{ulaznica.tvrtka}</div>
                        <div>{ulaznica.grad_mjesto}</div>
                        <div>{ulaznica.adresa}</div>
                        <div>{ulaznica.postanski_broj} {ulaznica.grad_mjesto}</div>
                        <div>{ulaznica.mobitel}</div>
                        <div>{ulaznica.drzava}</div>
                        <div>{ulaznica.kategorija}</div>
                        <div><button className="btn btn-sm btn-success" onClick={() => onSpojiUlaznicu(ulaznica)}>Spoji ulaznicu</button></div>
                      </div>
                    ))}
                </div>
                  } */}
                <button className="btn btn-sm btn-primary mt-3 mb-3" onClick={() => setIsPostojeciPredstavnikVisible(!isPostojeciPredstavnikVisible)}>Prikaži predstavnike svih izlagača</button>
                {isPostojeciPredstavnikVisible && <IzlagacPredstavniciPostojeci izlagac={props.izlagac} event={props.event}/>}
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-3">Ime</div>
          <div className="col-3">Prezime</div>
          <div className="col-3">E-mail</div>
          <div className="col-3">
          </div>
        </div>
        {popis.map((predstavnik) => <IzlagacPredstavnik izlagacDetalji={props.izlagacDetalji} ucitajPredstavnike={ucitajPredstavnike} predstavnik={predstavnik.id_predstavnika} key={predstavnik.id_predstavnika} id_zapisa={predstavnik.id_zapisa} predstavnik_email={predstavnik.email} izlagac={props.izlagac} event={props.event} />)}
      </div>
    </div>
  );
};

export default IzlagacPredstavnici;
