import React, { Component } from "react";

import Config from "../../config.json";
import PrintZebra from "./PrintZebra.jsx";
import PrintComponents from "react-print-components";
import PrintToZebra from "./PrintToZebra";
import ReactToPrint from "react-to-print";

import PrintButton from "./PrintButton";

import "./UpisOsobe.css";

import axios from "axios";
import Cookies from "js-cookie";
import jwt from "jsonwebtoken";

class UpisOsobe extends Component {

  state = {
    osoba: {
      oslovljavanje: "",
      ime: "",
      prezime: "",
      email: "",
      telefon: "",
      mobitel: "",
      tvrtka: "",
      funkcija: "",
      djelatnost: "",
      djelatnostList: [],
      adresa: "",
      postanski_broj: "",
      grad_mjesto: "",
      drzava: "Hrvatska",
      drzavaList: [],
      kategorija: "Posjetitelj",
      kategorijaList: [],
      vezane_novosti: false,
      nevezane_novosti: false,
      novosti_o_proizvodima: false,
      barcode: "",
      barcode_app: "",
      friendlyid: "",
      azurirano: false,
      vrsta_registracije: "Sajam",
    },
    oslovljavanje: "",
    ime: "",
    prezime: "",
    email: "",
    telefon: "",
    mobitel: "",
    tvrtka: "",
    funkcija: "",
    djelatnost: "",
    djelatnostList: [],
    adresa: "",
    postanski_broj: "",
    grad_mjesto: "",
    drzava: "HR",
    drzavaList: [],
    kategorija: "Posjetitelj",
    kategorijaList: [],
    vezane_novosti: false,
    nevezane_novosti: false,
    novosti_o_proizvodima: false,
    barcode: "",
    friendlyid: "",
    azurirano: false,
    upisano: false,
    vrsta_registracije: "Sajam",
    username: jwt.decode(Cookies.get("token")).username !== "infolab",
    prikaziDupliciraj: false,
    prikaziIspisAkreditacije: true,
    edit: false
  };

  componentDidMount() {
    this.getDjelatnostiList();
    this.getKategorijeList();
    //this.getPopisDrzava();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.edit !== this.props.edit) {
      this.setState({ edit: this.props.edit })
    }
    if (prevProps.osoba !== this.props.osoba) {
      this.setState({ osoba: this.props.osoba });
    }
  }

  handleChange = (event) => {
    if (this.state.azurirano || this.state.upisano) {
      return false;
    }

    if (event.target.name === 'ime' || event.target.name === 'prezime') {
      this.setState({
        ...this.state,
        osoba: {
          ...this.state.osoba,
          id: undefined,
          [event.target.name]: event.target.value
        },
        edit: false,
        azurirano: false,
        upisano: false,
        prikaziDupliciraj: false,
        prikaziIspisAkreditacije: false
      })
    } else {
      this.setState({
        osoba: {
          ...this.state.osoba,
          [event.target.name]: event.target.value,
        },
        prikaziIspisAkreditacije: false
      });
    }

  };

  handleSubmit = async (event) => {
    event.preventDefault();
    //console.log(this.state);
    let url = "";
    {
      this.state.osoba.id
        ? (url = Config.apiEndpoint + "/event/108/osobe/edit")
        : (url = Config.apiEndpoint + "/event/108/osobe/create");
    }
    try {
      const response = await axios.post(
        url,
        {
          data: this.state.osoba,
          event: 107
        },
        {
          headers: {
            authorization: Cookies.get("token"),
          },
        }
      );
      this.state.osoba.id
        ? response.data.affectedRows && this.setState({ azurirano: true, prikaziIspisAkreditacije: true, edit: false })
        : this.setState({
            azurirano: false,
            upisano: true,
            edit: false,
            prikaziIspisAkreditacije: true,
            osoba: {
              ...this.state.osoba,
              barcode: response.data.barcode,
              barcode_app: response.data.barcode_app,
              friendlyid: response.data.friendlyid,
            },
          });
    } catch (error) {
      alert("Problem sa spajanjem na server.");
    }

    //this.state.osoba.id && this.props.hideModal();
  };

  getDjelatnostiList = () => {
    fetch(`${Config.apiEndpoint}/event/djelatnosti/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        event: 102,
        authorization: Cookies.get("token"),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          djelatnostList: res,
        });
      })
      .catch((err) => console.log(err));
  };

  getKategorijeList = () => {
    fetch(`${Config.apiEndpoint}/event/categories/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        event: 102,
        authorization: Cookies.get("token"),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          kategorijaList: res,
        });
      })
      .catch((err) => console.log(err));
  };

  getPopisDrzava = () => {
    fetch("https://restcountries.eu/rest/v2/all", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          drzavaList: res,
        });
      });
  };

  closeModalAfterPrint = () => {
    //this.props.afterPrint;
  };

  prikaziDupliciraj = barcode => {
    this.setState({ prikaziIspisAkreditacije: false, prikaziDupliciraj: true });
    axios.put("https://api.horeca-adria.com:8081/ulaz/vrijeme",
    {
      barcode: this.state.osoba.barcode
    })
  }

  dupliciraj = () => {
    this.setState(prevState => ({
      ...prevState,
      osoba: {
        ...prevState.osoba,
        ime: '',
        prezime: '',
        id: undefined
      },
      edit: false,
      azurirano: false,
      upisano: false,
      prikaziDupliciraj: false
    }))
  }

  render() {
    return (
      <div className='container screen'>
        <form onSubmit={this.handleSubmit}>
          <div className='form-group row' style={{ marginBottom: 0 }}>
            <label
              htmlFor='ime'
              className='col-sm-6 col-form-label font-weight-bold text-left'
            >
              Ime
            </label>
            <label
              htmlFor='prezime'
              className='col-sm-6 col-form-label font-weight-bold text-left'
            >
              Prezime
            </label>
          </div>
          <div className='form-group row'>
            <div className='col-sm-6'>
              <input
                type='text'
                className='form-control font-weight-bold'
                style={{ height: "60px", fontSize: "30px" }}
                id='ime'
                name='ime'
                value={this.state.osoba.ime}
                onChange={this.handleChange}
                required
                //disabled={this.state.osoba.id}
                autoComplete='off'
              />
            </div>
            <div className='col-sm-6'>
              <input
                type='text'
                className='form-control font-weight-bold'
                style={{ height: "60px", fontSize: "30px" }}
                id='prezime'
                name='prezime'
                value={this.state.osoba.prezime}
                onChange={this.handleChange}
                required
                //disabled={this.state.osoba.id}
                autoComplete='off'
              />
            </div>
          </div>
          <div className='form-group row'>
            <label
              htmlFor='tvrtka'
              className='col-sm-2 col-form-label font-weight-bold'
              style={{ height: "60px" }}
            >
              Tvrtka
            </label>
            <div className='col-sm-10'>
              <input
                type='text'
                className='form-control font-weight-bold'
                style={{ height: "60px", fontSize: "30px" }}
                id='tvrtka'
                name='tvrtka'
                value={this.state.osoba.tvrtka}
                onChange={this.handleChange}
                autoComplete='off'
              />
            </div>
          </div>
          <div className='form-group row'>
            <label
              htmlFor='adresa'
              className='col-sm-2 col-form-label font-weight-bold'
            >
              Adresa
            </label>
            <div className='col-sm-10'>
              <input
                type='text'
                className='form-control font-weight-bold'
                id='adresa'
                name='adresa'
                value={this.state.osoba.adresa}
                onChange={this.handleChange}
                required
                autoComplete='off'
              />
            </div>
          </div>
          <div className='form-group row'>
            <label
              htmlFor='postanski_broj'
              className='col-sm-2 col-form-label font-weight-bold'
            >
              Poštanski br.
            </label>
            <div className='col-sm-2'>
              <input
                type='number'
                className='form-control font-weight-bold'
                id='postanski_broj'
                name='postanski_broj'
                value={this.state.osoba.postanski_broj}
                onChange={this.handleChange}
                required
                autoComplete='off'
              />
            </div>
            <label
              htmlFor='grad_mjesto'
              className='col-sm-2 col-form-label font-weight-bold'
            >
              Grad
            </label>
            <div className='col-sm-6'>
              <input
                type='text'
                className='form-control font-weight-bold'
                id='grad_mjesto'
                name='grad_mjesto'
                value={this.state.osoba.grad_mjesto}
                onChange={this.handleChange}
                required
                autoComplete='off'
              />
            </div>
          </div>
          <div className='form-group row'>
            <label
              htmlFor='email'
              className='col-sm-2 col-form-label font-weight-bold'
            >
              E-mail
            </label>
            <div className='col-sm-10'>
              <input
                type='email'
                className='form-control font-weight-bold'
                id='email'
                name='email'
                value={this.state.osoba.email}
                onChange={this.handleChange}
                required
                autoComplete='off'
              />
            </div>
          </div>
          <div className='form-group row'>
            <label
              htmlFor='funkcija'
              className='col-sm-2 col-form-label font-weight-bold'
            >
              Funkcija
            </label>
            <div className='col-sm-4 font-weight-bold'>
              <input
                type='text'
                className='form-control font-weight-bold'
                id='funkcija'
                name='funkcija'
                value={this.state.osoba.funkcija || ""}
                onChange={this.handleChange}
                autoComplete='off'
              />
            </div>
            <label
              htmlFor='djelatnost'
              className='col-sm-2 col-form-label font-weight-bold'
            >
              Djelatnost
            </label>
            <div className='col-sm-4'>
              <select
                type='text'
                className='form-control font-weight-bold'
                id='djelatnost'
                name='djelatnost'
                value={this.state.osoba.djelatnost}
                onChange={this.handleChange}
                required
              >
                <option value=""></option>
                {this.state.djelatnostList.map((djelatnost) => (
                  <option
                    key={djelatnost.id}
                    //selected={this.state.osoba.djelatnost === djelatnost.naziv}
                  >
                    {djelatnost.naziv}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='form-group row'>
            <label
              htmlFor='telefon'
              className='col-sm-2 col-form-label font-weight-bold'
            >
              Telefon
            </label>
            <div className='col-sm-4'>
              <input
                type='phone'
                className='form-control font-weight-bold'
                id='telefon'
                name='telefon'
                value={this.state.osoba.telefon}
                onChange={this.handleChange}
                autoComplete='off'
              />
            </div>

            <label
              htmlFor='mobitel'
              className='col-sm-2 col-form-label font-weight-bold'
            >
              Mobitel
            </label>
            <div className='col-sm-4'>
              <input
                type='mobile'
                className='form-control font-weight-bold'
                id='mobitel'
                name='mobitel'
                value={this.state.osoba.mobitel}
                onChange={this.handleChange}
                autoComplete='off'
                required
              />
            </div>
          </div>

          <div className='form-group row'>
            <label
              htmlFor='drzava'
              className='col-sm-2 col-form-label font-weight-bold'
            >
              Država
            </label>
            <div className='col-sm-4'>
              <select
                type='text'
                className='form-control font-weight-bold'
                id='drzava'
                name='drzava'
                value={this.state.osoba.drzava}
                onChange={this.handleChange}
                required
              >
                <option value='Hrvatska'>Hrvatska</option>
                <option value='Slovenija'>Slovenija</option>
                <option value='Mađarska'>Mađarska</option>
                <option value='Austrija'>Austrija</option>
                <option value='Njemačka'>Njemačka</option>
                <option value='Bosna i Hercegovina'>Bosna i Hercegovina</option>
                <option value='Srbija'>Srbija</option>
                <option value='Crna Gora'>Crna Gora</option>
                <option value='Sjeverna Makedonija'>Sjeverna Makedonija</option>
                <option value='Turska'>Turska</option>
                <option value='Ostalo'>Ostalo</option>
              </select>
            </div>
            <label
              htmlFor='kategorija'
              className='col-sm-2 col-form-label font-weight-bold'
            >
              Kategorija
            </label>
            <div className='col-sm-4'>
              <select
                type='text'
                className='form-control font-weight-bold'
                style={{ backgroundColor: "white" }}
                id='kategorija'
                name='kategorija'
                value={
                  !this.props.edit ? "Posjetitelj" : this.state.osoba.kategorija
                }
                onChange={this.handleChange}
                required
                //disabled={this.state.username}
              >
                <option value='' />
                {this.state.kategorijaList.map((kategorija) => (
                  <option key={kategorija.id} value={kategorija.naziv}>
                    {kategorija.naziv}
                  </option>
                ))}
                <option value='Organizator'>Organizator</option>
                <option value='Staff'>Staff</option>
                <option value='Press'>Press</option>
                <option value='Security'>Security</option>
              </select>
            </div>
          </div>
          <div className='col-sm-12 mt-5' style={{ display: 'flex', justifyContent: 'space-around' }}>
            {this.state.prikaziDupliciraj && (
              <button
              type='button'
              className='btn btn-info'
              style={{ marginRight: "30px" }}
              onClick={this.dupliciraj}
            >
              Dupliciraj
            </button>
            )}
            {this.state.edit ? (
              <button
                type='submit'
                className='btn btn-primary'
                style={{ marginRight: "30px" }}
              >
                Spremi promjene
              </button>
            ) : (
              !this.state.azurirano && !this.state.upisano && (
                <button
                  type='submit'
                  className='btn btn-primary'
                  style={{
                    marginRight: "30px",
                    backgroundColor: "#0069d9",
                    borderColor: "#0069d9",
                    width: "145px",
                  }}
                >
                  Upiši
                </button>
              )
            )}
            {this.state.osoba.barcode && this.state.prikaziIspisAkreditacije && (
              <div onClick={() => this.prikaziDupliciraj(this.state.osoba.barcode)}>
              <PrintComponents
                trigger={
                  <div
                    className='btn btn-success cursor'
                    style={{
                      backgroundColor: "#218838",
                      borderColor: "#218838",
                      width: "145px",
                      cursor: "pointer",
                    }}
                  >
                    Ispis akreditacije
                  </div>
                }
              >
                <PrintZebra osoba={this.state.osoba} />
              </PrintComponents>
              </div>
            )}
            {this.state.azurirano && <div>Upisano / Ažurirano!</div>}
          </div>
        </form>
        {this.state.osoba.barcode && <div></div>}
      </div>
    );
  }
}

export default UpisOsobe;