import React, { useState } from "react";
import axios from 'axios';
import Cookies from "js-cookie";

import Config from "../../config.json";

const IzlagacForma = props => {
  const [izlagac, setIzlagac] = useState();
  const [isVisibleUpisUspjesan, setIsVisibleUpisUspjesan] = useState(false);

  const upisiIzlagaca = () => {
    axios.post("https://api.horeca-adria.com:8081/izlagac/",
      {
        izlagac: izlagac
      },
      {
        headers: {
          authorization: Cookies.get("token")
        }
      }).then(response => {
        if (response.data.result) {
          setIsVisibleUpisUspjesan(true);
          console.log(response);
        } else {
          console.log(response);
        }});

    // fetch(url, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     authorization: Cookies.get("token"),
    //   },
    //   body: JSON.stringify({
    //     organizator: 1,
    //     naziv: izlagac.naziv,
    //     mjesto: izlagac.mjesto,
    //     email: izlagac.email,
    //     adresa: izlagac.adresa,
    //     postanski_broj: izlagac.postanski_broj,
    //     drzava: izlagac.drzava,
    //     telefon: izlagac.telefon,
    //     web_adresa: izlagac.web_adresa,
    //     pozicija: izlagac.pozicija,
    //   }),
    // })
    //   .then((res) => {
    //     this.props.zatvoriFormu();
    //     this.props.refresh();
    //     //console.table(res);
    //     //const { event } = this.props.match.params;
    //     //this.props.history.push(`/event/${event}/izlagaci/list`);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

    return (
      <div className="mb-5">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="naziv"
              onChange={event => setIzlagac({ ...izlagac, naziv: event.target.value })}
            />
            <label htmlFor="naziv">Naziv izlagača</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="email"
              onChange={event => setIzlagac({ ...izlagac, email: event.target.value })}
            />
            <label htmlFor="email">E-mail</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="adresa"
              onChange={event => setIzlagac({ ...izlagac, adresa: event.target.value })}
            />
            <label htmlFor="adresa">Adresa</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="postanski_broj"
              onChange={event => setIzlagac({ ...izlagac, postanski_broj: event.target.value })}
            />
            <label htmlFor="postanski_broj">Poštanski broj</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="mjesto"
              onChange={event => setIzlagac({ ...izlagac, grad_mjesto: event.target.value })}
            />
            <label htmlFor='mjesto'>Mjesto</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="drzava"
              onChange={event => setIzlagac({ ...izlagac, drzava: event.target.value })}
            />
            <label htmlFor='drzava'>Država</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="telefon"
              onChange={event => setIzlagac({ ...izlagac, telefon: event.target.value })}
            />
            <label htmlFor="telefon">Telefon</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="web_adresa"
              onChange={event => setIzlagac({ ...izlagac, web_adresa: event.target.value })}
            />
            <label htmlFor="web_adresa">Web adresa</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="opis"
              onChange={event => setIzlagac({ ...izlagac, opis: event.target.value })}
            />
            <label htmlFor="pozicija">Opis</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="pozicija"
              onChange={event => setIzlagac({ ...izlagac, pozicija: event.target.value })}
            />
            <label htmlFor="pozicija">Pozicija</label>
          </div>
          <div className="text-center">
            <button className="btn btn-primary" onClick={upisiIzlagaca}>Upiši izlagača</button>
          </div>
          {isVisibleUpisUspjesan && <div>Upis uspješan</div>}
      </div>
    );
  }

export default IzlagacForma;
