import React, { useEffect, useState } from "react";
import axios from 'axios';
import Cookies from "js-cookie";
import Barcode from "react-barcode";


const IzlagacPredstavnik = (props) => {

  const [predstavnik, setPredstavnik] = useState({
    ime: '',
    prezime: '',
    email: '',
    password: ''
  });
  const [detalji, setDetalji] = useState({});
  const [ulaznice, setUlaznice] = useState([]);
  const [postojeceUlaznice, setPostojeceUlaznice] = useState([]);
  const [spojenaUlaznica, setSpojenaUlaznica] = useState({});

  useEffect(() => {
    ucitaj();
    ucitajUlaznicu();
    ucitajDetaljeIzlagaca();
    onProvjeriPostojeceUlaznice();
  },[]);

  const ucitaj = async () => {
    const response = await axios.get(
      "https://api.horeca-adria.com:8081/izlagac/predstavnik/" + props.predstavnik,
      {
        headers: {
          authorization: Cookies.get("token"),
        },
      }
    );
    setPredstavnik(response.data.result[0]);
  }

  const ucitajUlaznicu = () => {
    axios.get("https://api.horeca-adria.com:8081/izlagac/predstavnik/ulaznica/" + props.predstavnik + "/" + props.event,
    {
      headers: {
        authorization: Cookies.get("token")
      },
    }).then(response => {
      response.data.result && setSpojenaUlaznica(response.data.result[0])
    });
  }

  const onProvjeriPostojeceUlaznice = () => {
    axios.get("https://api.horeca-adria.com:8081/ulaznica/" + props.event + "/" + props.predstavnik_email, 
    {
      headers: {
        authorization: Cookies.get("token")
      }
    }).then(response => {
      setPostojeceUlaznice(response.data.postojeceUlaznice);
      //setSpojenaUlaznica(response.data.spojenaUlaznica);
    });
  }

  const onIzbrisiIzlagacPredstavnikEvent = () => {
    axios.delete("https://api.horeca-adria.com:8081/izlagac/predstavnik/event/" + props.id_zapisa, 
    {
      headers: {
        authorization: Cookies.get("token")
      }
    }).then(response => {
      props.ucitajPredstavnike();
    })
  }

  const onSpojiUlaznicu = (izlagac, predstavnik, visitor, event) => {
    axios.put("https://api.horeca-adria.com:8081/izlagac/predstavnik/event/",
    {
      izlagac,
      predstavnik,
      visitor,
      event
    },
    {
      headers: {
        authorization: Cookies.get("token"),
      }
    }).then(response => {
      if (response.data.error) {
        return false;
      }

      setSpojenaUlaznica(postojeceUlaznice.filter(ulaznica => ulaznica.id == visitor)[0]);
    });
  }

  const onObrisiSpojSaUlaznicom = (izlagac, predstavnik, visitor, event) => {
    axios.put("https://api.horeca-adria.com:8081/izlagac/predstavnik/event/ulaznica-delete/",
    {
      izlagac,
      predstavnik,
      visitor,
      event 
    },
    {
      headers: {
        authorization: Cookies.get("token")
      }
    }).then(response => {
      if (response.data.error) {
        return false;
      }

      setSpojenaUlaznica({});
    })
  }

  const ucitajDetaljeIzlagaca = () => {
    setDetalji({
      naziv: props.izlagacDetalji.naziv,
      grad_mjesto: props.izlagacDetalji.grad_mjesto,
      mobitel: props.izlagacDetalji.telefon,
      adresa: props.izlagacDetalji.adresa,
      postanski_broj: props.izlagacDetalji.postanski_broj,
      drzava: props.izlagacDetalji.drzava
    });
  }

  const onKreirajUlaznicu = () => {
    axios.post("https://api.horeca-adria.com:8081/ulaznica/predstavnik/",
    {
      ulaznica: {
        ime: predstavnik.ime,
        prezime: predstavnik.prezime,
        email: predstavnik.email,
        tvrtka: detalji.naziv,
        grad_mjesto: detalji.grad_mjesto,
        mobitel: detalji.mobitel, 
        adresa: detalji.adresa,
        postanski_broj: detalji.postanski_broj,
        drzava: detalji.drzava
      },
      event: props.event,
      predstavnik: predstavnik.id,
      izlagac: props.izlagac
    },
    {
      headers: {
        authorization: Cookies.get("token")
      }
    }).then(response => console.log(response))
  }

  const snimiUpdatePredstavnika = () => {
    axios.put("https://api.horeca-adria.com:8081/izlagac/predstavnik/",
    {
      id: predstavnik.id,
      ime: predstavnik.ime,
      prezime: predstavnik.prezime
    },
    {
      headers: {
        authorization: Cookies.get("token")
      }
    }).then(response => ucitaj());
  }

  return (
    <div className="container">
      <div className="offcanvas offcanvas-end" tabIndex="-1" id={"offcanvasIzmjenaPredstavnika" + props.id_zapisa} aria-labelledby={"offcanvasIzmjenaPredstavnikaLabel" + props.id_zapisa}>
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id={"offcanvasIzmjenaPredstavnikaLabel" + props.id_zapisa}>Detalji/izmjena predstavnika</h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <div className="card m-3 p-3 mb-5">
              <div className="form-floating mb-3">
                <input
                    type="text"
                    name="ime"
                    className="form-control"
                    value={predstavnik.ime}
                    onChange={(event) => setPredstavnik({ ...predstavnik, ime: event.target.value })}
                />
                <label htmlFor="ime" className="for-label">Ime</label>
              </div>
              <div className="form-floating mb-3">
                  <input
                    type="text"
                    name="prezime"
                    className="form-control"
                    value={predstavnik.prezime}
                    onChange={(event) => setPredstavnik({ ...predstavnik, prezime: event.target.value })}
                  />
                  <label htmlFor="prezime" className="for-label">Prezime</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  value={predstavnik.email}
                  onChange={(event) => setPredstavnik({ ...predstavnik, email: event.target.value })}
                  disabled
                />
                <label htmlFor="email" className="for-label">E-mail</label>
              </div>       
              <div className="form-floating mb-3">
                <input
                  type="text"
                  name="password"
                  className="form-control"
                  value={predstavnik.password}
                  onChange={(event) => setPredstavnik({ ...predstavnik, password: event.target.value })}
                  disabled
                />
                <label htmlFor="password" className="for-label">Password</label>
              </div>       
                <div className="col-sm-12 text-center">
                  <button className="btn btn-success" onClick={snimiUpdatePredstavnika}>Snimi promjene</button>
                </div>
            </div>
                {spojenaUlaznica && spojenaUlaznica.barcode ? (
                  <div>
                    <div>Spojena ulaznica:</div>
                    <div><Barcode value={spojenaUlaznica.barcode_app} /></div>
                    <h3>{spojenaUlaznica.friendlyid}</h3>
                    <div><button className="btn btn-sm btn-primary" onClick={() => onObrisiSpojSaUlaznicom(props.izlagac, props.predstavnik, spojenaUlaznica.id, props.event)}>Odspoji ulaznicu</button></div>
                  </div>
                ) : (
                  postojeceUlaznice.length ? (
                    <div className="card mt-3 mb-3">
                      <h4><strong>Ulaznica u sustavu {props.event}</strong></h4>
                      <div>{postojeceUlaznice.map(ulaznica => (
                        <div className="card" key={ulaznica.id}>
                          <h5>{ulaznica.ime} {ulaznica.prezime}</h5>
                          <h6>{ulaznica.tvrtka}</h6>
                          <h6>{ulaznica.grad_mjesto}</h6>
                          <h6>{ulaznica.friendlyid}</h6>
                          <button className="btn btn-sm btn-primary" onClick={() => onSpojiUlaznicu(props.izlagac, props.predstavnik, ulaznica.id, props.event)}>Spoji ulaznicu</button>
                        </div>
                      ))}</div>
                    </div>
                    ) : (
                      <div>
                        <div>Nema ulaznice za sajam {props.event} u sustavu</div>
                        <h4 className="mt-3 mb-3">Podaci za novu ulaznicu</h4>
                        <div className="form-floating mb-3">
                          <input type="text" name="tvrtka" className="form-control" value={detalji.naziv} onChange={event => setDetalji({ ...detalji, naziv: event.target.value})} />
                          <label htmlFor="tvrtka" className="for-label" value={props.tvrtka}>Tvrtka</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input type="text" name="grad_mjesto" className="form-control" value={detalji.grad_mjesto} onChange={event => setDetalji({ ...detalji, grad_mjesto: event.target.value })} />
                          <label htmlFor="grad_mjesto" className="for-label">Grad</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input type="text" name="mobitel" className="form-control" value={detalji.mobitel} onChange={event => setDetalji({ ...detalji, mobitel: event.target.value })} />
                          <label htmlFor="mobitel" className="for-label">Mobitel</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input type="text" name="adresa" className="form-control" value={detalji.adresa} onChange={event => setDetalji({ ...detalji, adresa: event.target.value })} />
                          <label htmlFor="adresa" className="for-label">Adresa</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input type="text" name="postanski_broj" className="form-control" value={detalji.postanski_broj} onChange={event => setDetalji({ ...detalji, postanski_broj: event.target.value })} />
                          <label htmlFor="postanski_broj" className="for-label">Poštanski broj</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input type="text" name="drzava" className="form-control" value={detalji.drzava} onChange={event => setDetalji({ ...detalji, drzava: event.target.value })} />
                          <label htmlFor="drzava" className="for-label">Država</label>
                        </div>
                        <button className="btn btn-sm btn-primary" onClick={onKreirajUlaznicu}>Kreiraj ulaznicu</button>
                      </div>

                    )
                  )}
        </div>
      </div>
      <div className="row">
        <div className="col-3">{predstavnik.ime}</div>
        <div className="col-3">{predstavnik.prezime}</div>
        <div className="col-3">{predstavnik.email}</div>
        <div className="col-3">
          <div className="row mb-3">
            <div className="col-6">
              <a className="btn btn-sm btn-primary" data-bs-toggle="offcanvas" href={"#offcanvasIzmjenaPredstavnika" + props.id_zapisa} role="button" aria-controls={"offcanvasIzmjenaPredstavnika" + props.id_zapisa}>
                Detalji
              </a></div>
            <div className="col-6"><button className="btn btn-sm btn-secondary" title="Izbriši sa sajma" onClick={onIzbrisiIzlagacPredstavnikEvent}>Izbriši</button></div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default IzlagacPredstavnik;
