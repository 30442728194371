import React, { useState, useEffect, useRef } from "react";

import { Modal } from "./Modal";
import UpisOsobe from "./UpisOsobe";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import Config from "../../config.json";
import jwt from "jsonwebtoken";
import Cookies from "js-cookie";

import "./Pult.css";

import axios from "axios";

const Pult = () => {
  const data = useSelector((state) => state);

  const barcodeRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [osoba, setOsoba] = useState({});
  const [button, setButton] = useState("");
  const [edit, setEdit] = useState(false);
  const [barcode, setBarcode] = useState("");

  const [popis, setPopis] = useState([]);

  const [pretraga, setPretraga] = useState("");

  const [menuItem, setMenuItem] = useState("pretraga");

  useEffect(() => {
    //auth();
  }, []);

  const handleChange = (event) => {
    setBarcode(event.target.value);
  };

  const handleChangePretraga = (event) => {
    setPretraga(event.target.value);
  };

  const ucitajOsobu = async () => {
    if (!barcode) {
      alert("Niste upisali barkod ili sigurnosni kod.");
      return false;
    }
    const url = Config.apiEndpoint + "/event/visitor/from-barcode";

    let barcode_velika_slova = barcode.toUpperCase();
    const response = await axios.get(url, {
      headers: {
        authorization: data.token,
        barcode: barcode_velika_slova,
      },
      mode: "cors",
    });
    setBarcode("");
    //setPopis(response.data);
    if (!response.data.length) {
      alert(
        "Posjetitelj sa upisanim barkodom/sigurnosnim kodom ne postoji, molimo pokušajte ponovno."
      );
      return false;
    }
    console.log("RESPONSE: ", response.data);
    setOsoba(response.data[0]);
    setButton("Ažuriraj");
    setEdit(true);
    setShowModal(true);
    console.log(response);
  };

  const ucitajOsobuIsPopisa = (osoba) => {
    setOsoba(osoba);
    setButton("Ažuriraj");
    setPopis([]);
    setEdit(true);
    setShowModal(true);
    setPopis([]);
  };

  const ucitajOsobuPoImenu = async (event) => {
    if (!pretraga) {
      //alert("Nije moguće pretraživati prazan pojam.");
      return false;
    }
    if (event.key === "Enter") {
      // const url = Config.apiEndpoint + "/event/visitor/pretraga-po-imenu";
      // const pretragaMalaSlova = pretraga.toLowerCase();
      // const response = await axios.post(
      //   url,
      //   {
      //     pretraga: pretragaMalaSlova,
      //   },
      //   {
      //     headers: {
      //       authorization: data.token,
      //       event: 107
      //     },
      //   }
      // );
      axios.get("https://api.horeca-adria.com:8081/pult/" + pretraga, 
      {
        headers: {
          authorization: Cookies.get("token")
        },
      }).then(response => {
        if (pretraga[0] == "1" && pretraga[1] == "0" && pretraga[2] == "8") {
          console.log("čitanje iz barkoda");
          if (response.data.result[0].djelatnost == "") {
            response.data.result[0].djelatnost = "Ostalo";
          }
          setOsoba(response.data.result[0]);
          setButton("Ažuriraj");
          setEdit(true);
          setShowModal(true);
          return false;
        } else {
          setPopis(response.data.result);
          setPretraga("");
          console.log(response);
        }
      });
      // console.log(response);
      // setPretraga("");
      // if (response.data == "Wrong token") return false;
      // setPopis(response.data);
    }
  };

  const noviPosjetitelj = () => {
    let osoba = {
      oslovljavanje: "",
      ime: "",
      prezime: "",
      email: "",
      telefon: "",
      mobitel: "",
      tvrtka: "",
      funkcija: "",
      djelatnost: "",
      djelatnostList: [],
      adresa: "",
      postanski_broj: "",
      grad_mjesto: "",
      drzava: "",
      drzavaList: [],
      kategorija: "",
      kategorijaList: [],
    };
    setOsoba(osoba);
    setEdit(false);
    setButton("Spremi");
    setShowModal(true);
  };

  const odustani = () => {
    //setShowModal(false);
    // promijeniti način pozivanja cancela -> kroz react router
    setShowModal(false);
    window.location.href = "/pult";
  };

  const auth = async () => {
    if (data.token) {
      const response = await axios.get(Config.apiEndpoint + "/check-token", {
        headers: {
          token: data.token,
          active_user: jwt.decode(data.token).id,
        },
      });
      if (response.data.response !== "Check passed") {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
  };

  return (
    <div>
      <Modal show={showModal} osoba={osoba}>
        <p
          style={{
            fontSize: 20,
            fontWeight: 700,
            marginTop: 5,
            marginBottom: 5,
          }}
        >
          Podaci o posjetitelju
        </p>
        <UpisOsobe
          osoba={osoba}
          edit={edit}
          azurirano={false}
          hideModal={() => setShowModal(false)}
        />
        {/* <button class="ml-4" onClick={() => printAkreditacije()}>
              Ispis na zebru
            </button> */}
        <div className='text-right'>
          <button
            className='btn btn-danger text-right sm-4 mt-2 mr-3'
            style={{ backgroundColor: "lightgrey", borderColor: "lightgrey" }}
            onClick={() => odustani()}
          >
            Povratak
          </button>
        </div>
      </Modal>
      <div className='col-9'>
        <h2>Ulaz na sajam</h2>
        <div style={{ marginBottom: "50px" }}>
          {/* <button
            className='btn btn-primary mr-2'
            style={{ backgroundColor: "#0069d9", borderColor: "#0069d9" }}
            onClick={() => setMenuItem("barkod")}
          >
            Učitaj posjetitelja putem barkoda
          </button> */}
          <button
            className='btn btn-primary mr-2'
            style={{ backgroundColor: "#0069d9", borderColor: "#0069d9" }}
            onClick={() => setMenuItem("pretraga")}
          >
            Pretraga
          </button>
          <button
            className='btn btn-primary'
            onClick={() => noviPosjetitelj()}
            style={{ backgroundColor: "#0069d9", borderColor: "#0069d9" }}
          >
            Novi posjetitelj
          </button>
        </div>

        <div style={{ border: "1px solid lightgrey" }}>
          {menuItem === "barkod" && (
            <div>
              <input
                type='text'
                name='barcode'
                value={barcode}
                onChange={handleChange}
                autoFocus
                onKeyUp={(event) => {
                  if (event.keyCode === 13) {
                    ucitajOsobu();
                  }
                }}
                style={{ backgroundColor: "lightgrey" }}
              />
              <button
                className='btn btn-primary'
                onClick={ucitajOsobu}
                style={{
                  marginLeft: 10,
                  backgroundColor: "#0069d9",
                  borderColor: "#0069d9",
                }}
              >
                Učitaj posjetitelja putem barkoda ili friendlyida
              </button>
            </div>
          )}
          {menuItem === "novi" && (
            <div>
              <button
                className='btn btn-primary ml-5'
                onClick={() => {
                  let osoba = {
                    oslovljavanje: "",
                    ime: "",
                    prezime: "",
                    email: "",
                    telefon: "",
                    mobitel: "",
                    tvrtka: "",
                    funkcija: "",
                    djelatnost: "",
                    djelatnostList: [],
                    adresa: "",
                    postanski_broj: "",
                    grad_mjesto: "",
                    drzava: "",
                    drzavaList: [],
                    kategorija: "",
                    kategorijaList: [],
                  };
                  setOsoba(osoba);
                  setButton("Spremi");
                  //setShowModal(true);
                }}
              >
                Novi posjetitelj
              </button>
              <UpisOsobe />
            </div>
          )}
        </div>
        {menuItem === "pretraga" && (
          <>
          {/* <div style={{ border: "1px solid lightgrey" }}>
            Upiši ime ili prezime:
            <input
              type='text'
              onChange={handleChangePretraga}
              onKeyUp={ucitajOsobuPoImenu}
              value={pretraga}
              autoFocus
            />
          </div> */}
          <div style={{ border: "1px solid lightgrey" }}>
            Upiši pojam (ime, prezime, tvrtka ili barkod):
            <input
              type='text'
              onChange={handleChangePretraga}
              onKeyUp={ucitajOsobuPoImenu}
              value={pretraga}
              autoFocus
            />
          </div>
          </>
        )}

        {popis.length > 0 && (
          <table className='table' style={{ width: "1000px", marginLeft: 'auto', marginRight: 'auto' }}>
            <thead>
              <tr>
                <th>Ime</th>
                <th>Prezime</th>
                <th>Tvrtka</th>
                <th>E-mail</th>
                <th>Vrsta registracije</th>
                <th>Sajam</th>
              </tr>
            </thead>
            <tbody>
              {popis.map((osoba) => (
                <tr
                  key={osoba.id}
                  onClick={() => ucitajOsobuIsPopisa(osoba)}
                  style={{ cursor: "pointer" }}
                >
                  <td>{osoba.ime}</td>
                  <td>{osoba.prezime}</td>
                  <td>{osoba.tvrtka}</td>
                  <td>{osoba.email}</td>
                  <td>{osoba.vrsta_registracije}</td>
                  <td>{osoba.event}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Pult;
