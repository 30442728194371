import React, { Component } from "react";
import IzlagacForma from "./IzlagacForma";
import Cookies from "js-cookie";
import axios from "axios";
import Config from "../../config.json";
import jwt from "jsonwebtoken";
import IzlagacImport from "./IzlagacImport";

class Izlagaci extends Component {
  state = {
    popis: [],
    eventid: 108,
    izlagacForma: false,
    importIzlagacaForma: false,
    popisZaImport: [],
    emailZaExport: "iva.svesko@turizaminfo.hr",
    kategorijaZaExport: "",
    emailZaExportZaApp: "iva.svesko@turizaminfo.hr",
    events: [],
    popisSajam: []
  };

  componentDidMount() {
    //this.auth();
    this.refresh();
    this.ucitajListuZaImport();
    this.ucitajEvente();
  }

  ucitajEvente = () => {
    axios.get('https://api.horeca-adria.com:8081/event', {
      headers: {
        authorization: Cookies.get("token")
      }
    }).then(response => this.setState({ events: response.data.result }))
  }

  onDodajIzlagacaNaSajam = izlagac => {
    axios.post('https://api.horeca-adria.com:8081/izlagac-event/', {
        izlagac: izlagac,
        event: this.state.eventid
      },
      {
        headers: {
          authorization: Cookies.get("token")
      }
    }).then(response => this.refresh());
  }

  onMakniIzlagacaSaSajma = izlagac => {
    axios.delete('https://api.horeca-adria.com:8081/izlagac-event/' + izlagac, {
      headers: {
        authorization: Cookies.get("token")
      }
    }).then(response => this.refresh());
  }

  refresh = async () => {

    const responseSvi = await axios.get("https://api.horeca-adria.com:8081/izlagac-event-nema/" + this.state.eventid, {
      headers: {
        authorization: Cookies.get("token"),
      }
    });
    if (responseSvi.data.error) {
      return false;
    }

    const responseSajam = await axios.get("https://api.horeca-adria.com:8081/izlagac-event/" + this.state.eventid, {
      headers: {
        authorization: Cookies.get("token")
      }
    })
    this.setState({
      popis: responseSvi.data.result,
      popisSajam: responseSajam.data.result
    });

  };

  ucitajListuZaImport = async () => {
    const urlImport =
      Config.apiEndpoint + "/event/:event/izlagac/lista-za-import";
    const response = await axios.get(urlImport, {
      headers: {
        authorization: Cookies.get("token"),
        eventid: this.state.eventid,
      },
    });
    if (response.data.error) {
      return false;
    }
    this.setState({
      popisZaImport: response.data,
    });
  };

  otvoriIzlagaca = (izlagac) => {
    window.location.href = '/event/izlagac/' + izlagac;
    // !this.props.noviEvent &&
    //   this.props.history.push(`/event/izlagac/${izlagac}`);
  };

  auth = async () => {
    const token = Cookies.get("token");

    if (token) {
      const response = await axios.get(Config.apiEndpoint + "/check-token", {
        headers: {
          token: token,
          active_user: jwt.decode(token).id,
        },
      });
      if (response.data.response !== "Check passed") {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
  };

  changeEvent = (event) => {
    this.setState(
      {
        eventid: event.target.value,
      },
      () => this.refresh()
    );
  };

  zatvoriFormu = () => {
    this.setState({
      izlagacForma: !this.state.izlagacForma,
    });
  };

  importIzlagaca = () => {
    this.setState({
      importIzlagacaForma: !this.state.importIzlagacaForma,
    });
  };

  onImport = () => {
    this.refresh();
    this.importIzlagaca();
  };

  posaljiExportAkreditacija = async () => {
    await axios.post(Config.apiEndpoint + "/export-akreditacija", {
      email: this.state.emailZaExport,
      kategorija: this.state.kategorijaZaExport
    });
  }

  onChangeEmailZaExport = (event) => {
    this.setState({ emailZaExport: event.target.value });
  }

  onChangeKategorijaZaExport = (event) => {
    this.setState({ kategorijaZaExport: event.target.value });
  }

  posaljiExportZaApp = async () => {
    await axios.post(Config.apiEndpoint + "/export-predstavnika-za-app", {
      email: this.state.emailZaExportZaApp
    });
  }

  onChangeEmailZaExportZaApp = (event) => {
    this.setState({ emailZaExportZaApp: event.target.value });
  }

  eksportAkreditacija = async () => {
    try {
      const response = await axios.get('https://api.horeca-adria.com:8081/export/predstavnici/' + this.state.eventid, {
        responseType: 'blob', // Set the response type to 'blob' to handle binary data,
        headers: {
          authorization: Cookies.get("token")
        }
      });

      // Create a URL for the blob data and a temporary anchor element for download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'akreditacije.xlsx'); // Specify the filename you want

      // Trigger the click event to start the download
      link.click();

      // Clean up by revoking the URL and removing the anchor element
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }

  eksportAplikacija = async () => {
    try {
      const response = await axios.get('https://api.horeca-adria.com:8081/export/predstavnici/aplikacija/' + this.state.eventid, {
        responseType: 'blob', // Set the response type to 'blob' to handle binary data,
        headers: {
          authorization: Cookies.get("token")
        }
      });

      // Create a URL for the blob data and a temporary anchor element for download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'aplikacija_podaci.xlsx'); // Specify the filename you want

      // Trigger the click event to start the download
      link.click();

      // Clean up by revoking the URL and removing the anchor element
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }

  render() {
    let id_eventa = 0;
    if (this.props.match && this.props.match.params.event) {
      id_eventa = this.props.match.params.event;
    } else {
      id_eventa = this.props.event;
    }
    return (
      <div>
        <div>
          <select
            name='eventSwitcher'
            id='eventSwitcher'
            value={this.state.eventid}
            onChange={(e) => this.changeEvent(e)}
          >
            {this.state.events.map(eventDetail => <option key={eventDetail.event_id} value={eventDetail.event_id}>{eventDetail.naziv}</option>)}
          </select>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <div className="text-right"><button className="btn btn-success m-3" onClick={this.eksportAkreditacija}>Eksport akreditacija</button></div>
              <div className="text-right"><button className="btn btn-success m-3" onClick={this.eksportAplikacija}>Eksport aplikacija podaci</button></div>
              {!this.props.noviEvent && (
                <div className='text-right'>
                  {/* {this.state.eventid === 102 && (
                    <button
                      className='btn btn-secondary mr-2 mb-2'
                      onClick={this.importIzlagaca}
                    >
                      Import izlagača sa prošlih sajmova
                    </button>
                  )} */}
                  <a className="btn btn-primary" data-bs-toggle="offcanvas" href="#offcanvasUpisNovogIzlagaca" role="button" aria-controls="offcanvasUpisNovogIzlagaca">
                    Upis novog izlagača
                  </a>
                  <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasUpisNovogIzlagaca" aria-labelledby="offcanvasUpisNovogIzlagacaLabel">
                  <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasUpisNovogIzlagacaLabel">Upis novog izlagača</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                  </div>
                  <div className="offcanvas-body">
                    <IzlagacForma />
                  </div>
                </div>
                </div>
              )}
              {this.state.importIzlagacaForma && (
                <IzlagacImport
                  popisZaImport={this.state.popisZaImport}
                  onImport={this.onImport}
                />
              )}
              {this.state.eventid}
              {/* {this.state.izlagacForma && (
                <IzlagacForma
                  eventid={this.state.eventid}
                  zatvoriFormu={this.zatvoriFormu}
                  refresh={this.refresh}
                />
              )} */}
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="text-right">
                      <a className="btn btn-warning" data-bs-toggle="offcanvas" href="#offcanvasIzlagaci" role="button" aria-controls="offcanvasIzlagaci">
                        Dodaj izlagača na sajam
                      </a>
                    </div>
                    <div>Izlagači na trenutnom sajmu ({this.state.events.filter(sajam => sajam.event_id == this.state.eventid).map(sajam => sajam.naziv)})</div>
                    <table className='table table-sm'>
                      <thead>
                        <tr>
                          <th className='naslov-popis'>Naziv</th>
                          <th className='naslov-popis'>Mjesto</th>
                          <th className='naslov-popis'></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.popisSajam.map((izlagac) => (
                          <tr
                            key={izlagac.id_zapisa}
                            className="event-izlagaci-row"
                          >
                            {this.props.noviEvent && (
                              <td>
                                <input type='checkbox' checked />
                              </td>
                            )}
                            <td>{izlagac.naziv}</td>
                            <td>{izlagac.grad_mjesto}</td>
                            <td>
                              <button className="btn btn-primary btn-sm" onClick={() => this.onMakniIzlagacaSaSajma(izlagac.id_zapisa)}>Ukloni</button>
                              <button className="btn btn-secondary btn-sm" onClick={() => this.otvoriIzlagaca(izlagac.id)}>Detalji</button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasIzlagaci" aria-labelledby="offcanvasIzlagaciLabel">
                      <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasIzlagaciLabel">Svi izlagači koji nisu na trenutnom sajmu</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                      </div>
                      <div className="offcanvas-body">
                        <table className='table table-sm'>
                          <thead>
                            <tr>
                              <th className='naslov-popis'>Naziv</th>
                              <th className='naslov-popis'>Mjesto</th>
                              <th className='naslov-popis'></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.popis.map((izlagac) => (
                              <React.Fragment key={izlagac.id}>
                                <tr
                                  className="event-izlagaci-row"
                                >
                                  {this.props.noviEvent && (
                                    <td>
                                      <input type='checkbox' checked />
                                    </td>
                                  )}
                                  <td>{izlagac.naziv}</td>
                                  <td>{izlagac.grad_mjesto}</td>
                                  <td>
                                    <button className="btn btn-primary btn-sm" onClick={() => this.onDodajIzlagacaNaSajam(izlagac.id)}>Dodaj</button>
                                    <button className="btn btn-secondary btn-sm" onClick={() => this.otvoriIzlagaca(izlagac.id)}>Detalji</button>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="3"><span style={{ fontSize: '12px' }}>{izlagac.opis}</span></td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      </div>
                    </div>
                </div>
              </div>
              <div style={{ marginTop: '50px' }}>
                Export akreditacija na e-mail: <input type="text" value={this.state.emailZaExport} onChange={this.onChangeEmailZaExport} />
                <select name="Kategorija" onChange={this.onChangeKategorijaZaExport}>
                  <option value=""></option>
                  <option value="Izlagač">Izlagač</option>
                  <option value='Organizator'>Organizator</option>
                  <option value='Staff'>Staff</option>
                  <option value='Press'>Press</option>
                  <option value='Security'>Security</option>
                </select>
                <button onClick={this.posaljiExportAkreditacija}>Pošalji</button>
                <div>
                Export predstavnika za app na e-mail: <input type="text" value={this.state.emailZaExportZaApp} onChange={this.onChangeEmailZaExportZaApp} />
                <button onClick={this.posaljiExportZaApp}>Pošalji</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Izlagaci;
