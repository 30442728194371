import React, { useEffect, useState } from "react";
import axios from 'axios';
import Cookies from "js-cookie";


const IzlagacUsluge = props => {

    const [novaUsluga, setNovaUsluga] = useState("");
    const [usluge, setUsluge] = useState([]);

    useEffect(() => {
        ucitajUsluge();
    },[])

    const onUpisUsluge = () => {
        axios.post("https://api.horeca-adria.com:8081/izlagac/usluga/", 
            {
            usluga: novaUsluga,
            izlagac: props.izlagac
            },
            { 
                headers: {
                    authorization: Cookies.get("token"),
                }
            }).then(response => console.log(response));

        setNovaUsluga("");
        ucitajUsluge();
    }

    const ucitajUsluge = () => {
        //console.log(props.izlagac);
        axios.get("https://api.horeca-adria.com:8081/izlagac/" + props.izlagac + "/usluga/",
        {
            headers: {
                authorization: Cookies.get("token")
            }
        }).then(response => setUsluge(response.data.result));
    }

    const onDelete = id => {
        axios.delete("https://api.horeca-adria.com:8081/izlagac/usluga/" + id + "/",
        {
            headers: {
                authorization: Cookies.get("token")
            }
        }).then(response => ucitajUsluge());
    }

  return (
    <div>
      <div
            className='izlagac-details-kategorije-naslov mt-5'
            style={{ textDecoration: "underline" }}
          >
            Usluge izlagača
          </div>
          {usluge.length === 0 ? (
            <div className='izlagac-details-kategorije-tekst'>
              Nema upisanih usluga
            </div>
          ) : (
            <div>
              <ul className="list-group">
                {usluge.map((usluga) => (
                  <li key={usluga.id} className="list-group-item">{usluga.usluga}</li>
                ))}
              </ul>
            </div>
          )}
          <a className="btn btn-primary" data-bs-toggle="offcanvas" href="#offcanvasUsluge" role="button" aria-controls="offcanvasUsluge">
            Ažuriraj usluge
          </a>
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasUsluge" aria-labelledby="offcanvasUslugeLabel">
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasUslugeLabel">Usluge izlagača</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body">
                <div>
                  <ul className="list-group">
                    {usluge.map((usluga) => (
                      <li key={usluga.id} className="list-group-item">
                        {usluga.usluga} 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3 ml-3" viewBox="0 0 16 16" onClick={() => onDelete(usluga.id)}>
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                      </svg>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="form-floating mb-3">
                  <input type="text" className="form-control" value={novaUsluga} onChange={event => setNovaUsluga(event.target.value)} />
                  <label>Upiši novu uslugu</label>
                  <button className="btn btn-primary mt-3 ml-3 mr-3" disabled={!novaUsluga.length} onClick={() => onUpisUsluge(props.izlagac)}>Upiši</button>
                </div>
              </div>
            </div>
    </div>
  )
};

export default IzlagacUsluge;
