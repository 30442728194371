import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { upisActiveEventa } from "./store/actions/xpocrm";
import logo from "./images/xpocrm_logo.png";
import Config from "./config.json";

import jwt from "jsonwebtoken";

import { Dropdown } from "react-bootstrap";

const UserNavBar = (props) => {
  //const [active_event, setActive_event] = useState("");
  //const [active_user, setActive_user] = useState("");
  const data = useSelector((state) => state);
  //console.log("DATA: ", data);
  const dispatch = useDispatch();
  const username = jwt.decode(data.token).username;

  const activeStyle = {
    color: "#007c89",
    fontWeight: "700",
  };

  const style = {
    display: "table",
    top: "0px",
    width: "100%",
    height: "60px",
    backgroundColor: "white",
    position: "absolute",
    zIndex: "500",
    alignItems: "center",
    borderBottom: "1px solid #dedddc",
  };

  const styleLi = {
    display: "inline",
    margin: "25px",
    color: "#241c15",
  };

  const styleLink = {
    color: "#241c15",
  };

  useEffect(() => {
    //const token = jwt.decode(Cookies.get("token"));
    //const token = Cookies.get("token");
    //checkToken();
    //setActive_event(token.active_event);
    //setActive_user(token.active_user);
  }, []);

  return (
    <div style={style}>
      <nav
        className='menu-font default-font float-left selfclear'
        style={{ display: "flex", marginTop: "20px" }}
      >
        <ul>
          <li style={styleLi}>
            <img src={logo} alt='Logo' style={{ width: "130px" }} />
          </li>
          {username === "infolab" && (
            <React.Fragment>
              <li style={styleLi}>
                <NavLink
                  to={`/event/izlagaci/list`}
                  style={styleLink}
                  activeStyle={activeStyle}
                >
                  Izlagači
                </NavLink>
              </li>
              <li style={styleLi}>
                <NavLink
                  to={`/event/osobe/list`}
                  style={styleLink}
                  activeStyle={activeStyle}
                >
                  Sudionici
                </NavLink>
              </li>
              {/* <li style={styleLi}>
                <NavLink
                  to={`/event/100/details/`}
                  style={styleLink}
                  activeStyle={activeStyle}
                >
                  Postavke
                </NavLink>
              </li> */}
              {/* <li style={styleLi}>
                <NavLink
                  to={`/event/100/djelatnosti/`}
                  style={styleLink}
                  activeStyle={activeStyle}
                >
                  Djelatnosti
                </NavLink>
              </li> */}
              {/* <li style={styleLi}>
                <NavLink
                  to={`/event/100/categories/`}
                  style={styleLink}
                  activeStyle={activeStyle}
                >
                  Kategorije
                </NavLink>
              </li> */}
              <li style={styleLi}>
                <NavLink
                  to={`/ulaz/scan/`}
                  style={styleLink}
                  activeStyle={activeStyle}
                >
                  Scan na ulazu
                </NavLink>
              </li>
              <li style={styleLi}>
                <NavLink
                  to={`/event/sastanci/`}
                  style={styleLink}
                  activeStyle={activeStyle}
                >
                  Sastanci
                </NavLink>
              </li>
            </React.Fragment>
          )}
          <li style={styleLi}>
            <NavLink to={`/pult/`} style={styleLink} activeStyle={activeStyle}>
              Pult
            </NavLink>
          </li>
        </ul>
      </nav>
      <nav
        className='float-right'
        style={{ marginTop: "20px", marginRight: "10px" }}
      >
        <ul>
          <li>
            <Dropdown>
              <Dropdown.Toggle variant='info' id='dropdown-basic'>
                Korisnički račun: {username}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {/* <Dropdown.Item href='/event/100/details'>
                  HoReCa Adria 2019
                </Dropdown.Item> */}
                <Dropdown.Item href='#/action-1'>
                  Relax Adria 2023
                </Dropdown.Item>
                {/* <Dropdown.Item href='#/action-1'>
                  Internet Adria 2020
                </Dropdown.Item>
                <Dropdown.Item href='#'>
                  <Link to='/event/upis'>Novi event</Link>
                </Dropdown.Item>
                <Dropdown.Item href='#/action-2'>
                  Postavke korisnika
                </Dropdown.Item> */}
                <Dropdown.Item>
                  <Link to='/logout'>Odjava</Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default UserNavBar;
