import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { upisActiveEventa, upisTokena } from "../../store/actions/xpocrm";
import Cookies from "js-cookie";
import axios from "axios";
import Config from "../../config.json";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const Authentication = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!username || !password) {
      alert("Morate ispuniti polja.");
      return false;
    }
    const url = Config.apiEndpoint + "/auth";
    const response = await axios.get(url, {
      headers: {
        username: username,
        password: password,
      },
    });
    //console.log("Response from auth: ", response.data);
    if (response.data.error) {
      setError(response.data.error);
    } else {
      dispatch(upisTokena(response.data.token));
      Cookies.set("token", response.data.token);
      //dispatch(upisActiveEventa(response.data.active_event));
      //window.location.href = "/";
    }
  };

  return (
    !token && (
      <div className='container'>
        <form onSubmit={handleSubmit}>
          <TextField
            label='Username'
            value={username}
            onInput={(e) => setUsername(e.target.value)}
          />
          <br />
          <TextField
            label='Password'
            type='password'
            value={password}
            onInput={(e) => setPassword(e.target.value)}
          />
          <br />
          <Button type='submit' variant='contained' color='primary' style={{ marginTop: '20px'}}>
            Log In
          </Button>
          {error && <div>Authentication error</div>}
        </form>

        {/* <div className='row'>
        <div className='col-12'>
          <form onSubmit={handleSubmit}>
            <div className='form-group text-left'>
              <label htmlFor='username'>Username</label>
              <input
                type='text'
                className='form-control'
                id='username'
                name='username'
                aria-describedby='emailHelp'
                onChange={(e) => handleChange(setUsername, e)}
              />
            </div>
            <div className='form-group text-left'>
              <label htmlFor='password'>Password</label>
              <input
                type='password'
                className='form-control'
                id='password'
                name='password'
                onChange={(e) => handleChange(setPassword, e)}
              />
            </div>
            <button
              type='submit'
              className='btn btn-primary w-100 button-secondary'
            >
              Log In
            </button> */}
        {/* <div className="container">
                <div className="row">
                  <div className="col-4" />
                  <div className="col-4">
                    <div className="input-group mt-3 mr-0">
                      <input
                        type="checkbox"
                        className="form-control"
                        aria-label="Keep me logged in"
                        id="keepMeLogged"
                        name="keepMeLogged"
                      />
                      <div className="form-control">
                        <label htmlFor="keepMeLogged">Keep me logged in</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-4" />
                </div>
                <div className="text-center" style={{ lineHeight: "24px" }}>
                  <p className="mr-3" style={{ display: "inline-block" }}>
                    <a href="/login/forgot-username/">Forgot username?</a>
                  </p>
                  .
                  <p className="ml-3" style={{ display: "inline-block" }}>
                    <a href="/login/forgot/"> Forgot password?</a>
                  </p>
                </div>
              </div> */}
        {/* </form>
        </div>
      </div> */}
      </div>
    )
  );
};

export default Authentication;
