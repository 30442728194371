import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from 'js-cookie';

const IzlagacPredstavniciPostojeci = (props) => {

    const [popis, setPopis] = useState([]);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        ucitaj();
    },[]);

    const filteredPopis = popis.filter(predstavnik => 
        predstavnik.ime.toLowerCase().includes(filter.toLowerCase()) ||
        predstavnik.prezime.toLowerCase().includes(filter.toLowerCase() ||
        predstavnik.email.toLowerCase().includes(filter.toLowerCase()))
    );

    const ucitaj = () => {
        axios.get("https://api.horeca-adria.com:8081/predstavnik/postojeci/ostali/" + props.izlagac + "/" + props.event, {
            headers: {
                authorization: Cookies.get("token")
            }
        }).then(response => setPopis(response.data.result));
    }

  return (
    <div>
        <div className="form-floating mt-3 mb-3">
            <input type="text" id="filter" className="form-control" onKeyUp={e => setFilter(e.target.value)}/>
            <label htmlFor="filter" className="for-control">Filter</label>
        </div>
      {filteredPopis.map(predstavnik => <div key={predstavnik.id}>{predstavnik.ime} {predstavnik.prezime} <button className="btn btn-sm btn-secondary">Dodaj na sajam i kreiraj ulaznicu</button></div>)}
    </div>
  )
};

export default IzlagacPredstavniciPostojeci;
