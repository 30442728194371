import React, { Component } from "react";
import Config from "../../config.json";
import axios from "axios";
import IzlagacPredstavnici from "./IzlagacPredstavnici.jsx";
import jwt from "jsonwebtoken";
import Cookies from "js-cookie";
import IzlagacGosti from "./IzlagacGosti.jsx";
import IzlagacOpis from "./IzlagacOpis.jsx";
import FileSaver from "file-saver";
import IzlagacUsluge from "./IzlagacUsluge";

class IzlagacDetalji extends Component {
  state = {
    usluge: [],
    predstavnici: [],
    sajmovi: [],
    funkcije: [],
    keywords: [],
    globalKeywords: [],
    defineKeywords: false,
    details: {},
    odDatumaBaza: '2022-10-20',
    doDatumaBaza: '2022-10-22',
    email: 'iva.svesko@turizaminfo.hr',
    upisiNovuUsluguForma: false,
    event: 0,
    responseIzvjestajCijeliIzlagac: ''
  };

  componentDidMount() {
    //this.auth();
    //this.refreshUsluge();
    //this.refreshFunkcije();
    //this.getGlobalKeywords();
    this.ucitajIzlagaca();
    //this.getKeywords();
    this.ucitajSajmove();
  }

  ucitajIzlagaca = async () => {
    const response = await axios.get(
      "https://api.horeca-adria.com:8081/izlagac/detalji/" + this.props.match.params.izlagac,
      {
        headers: {
          authorization: Cookies.get("token"),
        },
      }
    );
    this.setState({
      details: response.data.result[0],
      event: response.data.event
    });
  }

  ucitajSajmove = async () => {
    const response = await axios.get("https://api.horeca-adria.com:8081/izlagac/sajmovi/" + this.props.match.params.izlagac,
    {
      headers: {
        authorization: Cookies.get("token")
      }
    });
    this.setState({
      sajmovi: response.data.result
    });
  }

  /**
   * Keywordsi još nisu u funkciji, dok ih detaljno ne definiramo.
   */

  getGlobalKeywords = async () => {
    const organizator = 1;
    const url = Config.apiEndpoint + "/keywords/list";
    const response = await axios.get(url, {
      headers: {
        organizator: organizator,
      },
    });
    this.setState({ globalKeywords: response.data });
  };

  getKeywords = () => {
    const organizator = 1;
    const url = Config.apiEndpoint + "/event/izlagac/keywords/list";
    const response = axios.get(url, {
      headers: {
        organizator: organizator,
      },
    });
    this.setState({ keywords: response.data });
  };

  // refreshUsluge = async () => {
  //   const token = Cookies.get("token");
  //   const url = Config.apiEndpoint + "/izlagac/usluge/list";
  //   const response = await axios.get(url, {
  //     headers: {
  //       izlagac: this.props.match.params.izlagac,
  //       authorization: Cookies.get("token"),
  //     },
  //   });
  //   console.log(response.data);
  //   this.setState({
  //     usluge: response.data,
  //   });
  // };

  refreshFunkcije = () => {
    const organizator = 1;
    const izlagac = this.props.match.params.izlagac;
    const event = this.props.match.params.id;
    const url = Config.apiEndpoint + "/izlagac/funkcija/list";
    const response = axios.get(url, {
      headers: {
        organizator: organizator,
        izlagac: izlagac,
        event: event,
      },
    });
    this.setState({ funkcije: response.data });
  };

  auth = async () => {
    const token = Cookies.get("token");

    if (token) {
      const response = await axios.get(Config.apiEndpoint + "/check-token", {
        headers: {
          token: token,
          active_user: jwt.decode(token).id,
        },
      });
      if (response.data.response !== "Check passed") {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
  };

  posaljiIzvjestaj = async () => {
    let url = Config.apiEndpoint + '/izvjestaj-cijeli-izlagac';
    const response = await axios.post(url, {
      izlagac: this.props.match.params.izlagac,
      email: this.state.email,
      event: this.state.event
    });
    this.setState({ responseIzvjestajCijeliIzlagac: response.data });
  }

  downloadIzvjestaj = async () => {
    try {
      const response = await axios.get('https://api.horeca-adria.com:8081/izvjestaj-cijeli-izlagac/download/' + this.props.match.params.izlagac, {
        responseType: 'blob', // Set the response type to 'blob' to handle binary data,
        headers: {
          authorization: Cookies.get("token")
        }
      });

      // Create a URL for the blob data and a temporary anchor element for download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'izvjestaj.xlsx'); // Specify the filename you want

      // Trigger the click event to start the download
      link.click();

      // Clean up by revoking the URL and removing the anchor element
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }

  downloadIzvjestaja = async () => {
    const url = Config.apiEndpoint + "/izvjestaj-cijeli-izlagac-download"
    const response = await axios.post(url, {
      odDatumaBaza: this.state.odDatumaBaza,
      doDatumaBaza: this.state.doDatumaBaza,
      izlagac: this.props.match.params.izlagac,
      email: this.state.email
    });
    console.log(response);
    FileSaver.saveAs(response.data, "kontakti.xlsx");
  }

  onUpisiNovuUsluguIzlagaca = async (usluga, izlagac) => {
    const url = Config.apiEndpoint + "/izlagac/usluge/upis";
    await axios.post(url, {
      usluga: usluga,
      izlagac: izlagac
    },
    { headers: {
      authorization: Cookies.get("token"),
    }})

    this.setState({ novaUsluga: '' })
    this.refreshUsluge();
    
  }

  onIzbrisiUsluguIzlagaca = async (usluga, izlagac) => {
    const url = Config.apiEndpoint + "/izlagac/usluge/delete";
    const response = await axios.get(url, {
      headers: {
      authorization: Cookies.get("token"),
      id: usluga,
      izlagac: izlagac
      }});
    this.refreshUsluge();
  }

  render() {
    return (
      <div>
        <IzlagacOpis details={this.state.details} izlagac={this.props.match.params.izlagac} />
        <div>
          <IzlagacUsluge izlagac={this.props.match.params.izlagac} />
          {this.state.sajmovi.map(sajam => (
            <div key={sajam.event_id} className="card mt-4" style={{ width: '1400px', margin: '0px auto', border: '2px solid red' }}>
              <div className="card-body">
                <div className="card-title"><h2>{sajam.naziv}</h2></div>
                <div className="card-text">
                  <IzlagacPredstavnici izlagacDetalji={this.state.details} izlagac={this.props.match.params.izlagac} event={sajam.event_id} tvrtka={this.state.details.tvrtka} grad_mjesto={this.state.details.grad_mjesto} />
                  <IzlagacGosti izlagac={this.props.match.params.izlagac} event={sajam.event_id} izlagacDetalji={this.state.details} />
                </div>
              </div>
            </div>
            ))}
        </div>
        <div style={{ backgroundColor: 'lightgrey', width: '1000px', margin: '0px auto', marginTop: '50px', marginBottom: '50px' }}>
          <h5>Slanje izvještaja za cijelog dobavljača za sajam {this.state.details.event_naziv}</h5>
          {/* <div>
            <input type="text" name="odDatumaBaza" value={this.state.odDatumaBaza} onChange={event => this.setState({ odDatumaBaza: event.target.value })} />
            <input type="text" name="doDatumaBaza" value={this.state.doDatumaBaza} onChange={event => this.setState({ doDatumaBaza: event.target.value })} />
          </div> */}
          <div><input type="text" name="email" value={this.state.email} onChange={event => this.setState({ email: event.target.value })} style={{ width: '200px' }}/></div>
          <div><button className="btn btn-primary" onClick={this.posaljiIzvjestaj}>Pošalji</button></div>
          <div>{this.state.responseIzvjestajCijeliIzlagac && 'Izvještaj poslan na e-mail.'}</div>
          <div><button className="btn btn-secondary" onClick={this.downloadIzvjestaj}>Download izvještaja</button></div>
        </div>
      </div>
    );
  }
}

export default IzlagacDetalji;
